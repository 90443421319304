import React,{useEffect} from 'react'
import Topbar from './topbar'
import Navbar from './navbar'
import  Section  from './carousel'
import About from './about'
import Services from'./services'
import Contact from './contact'
import Footer from './footer'
import Career from './career'
import Intelliroutes from '../intelliroutes'

const Home = () => {

  useEffect(() => {
    const scrollToSection = () => {
      const hash = window.location.hash;
  
      if (hash) {
        const targetSection = document.querySelector(hash);
  
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
  
    scrollToSection();
  
    window.addEventListener('load', scrollToSection);
  
    return () => {
      window.removeEventListener('load', scrollToSection);
    };
  }, []);

  return (
    <div id="Home">
     
     <Topbar/>
     <Navbar/>
     <Section/>
     <div id="About">
     <About/>
     </div>
     <div id="Services">
     <Services/>
     </div>
     <div id="Career">
     <Career/>
     </div>
     <div id="Contact">
     <Contact/>
     </div>
     <Footer/>   
     
    </div>
  )
}

export default Home