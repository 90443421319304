import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Components/home'
import IntelliCareers from './Components/intelli-careers'
import Pagenotfound from './Components/pagenotfound'

const Intelliroutes = () => {
  return (
    <div>
         <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Home" element={<Home/>}/>  
        <Route path="/*" element={<Pagenotfound/>}/>  
        <Route path="/IntelliCareers" element={<IntelliCareers/>}/>  

        </Routes>
    </div>
  )
}

export default Intelliroutes