import React from 'react'
import './bootstrap.min.css'
import './App.css'
import Intelliroutes from './intelliroutes';


const App = () => {
  return (
    <div>
      <Intelliroutes/>

    </div>
   
  )
}

export default App

