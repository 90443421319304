import React from 'react'
import image from '../assets/images/pagenotfound.jpg'
const Pagenotfound = () => {
  return (
    <div class="container">
    <div class="text-center mt-5 ">
        <h1 alt="Page Not Found">404 Error - Page Not Found</h1>
      <img src={image} alt="Page not Found" class="img-fluid" style={{height: '400px'}}/>
    </div>
    </div>
  )
}

export default Pagenotfound