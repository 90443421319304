import React from 'react'

const Footer = () => {
  return (
    <div>
<div class="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-4">Our Office</h5>
                    <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>3333 Warrenville Road, Suite 200, Lisle, Illinois 60532, United States</p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+1 (980) 525-4567</p>
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>hr@intellivisioninc.com</p>
                    <div class="d-flex pt-3">
                        <a class="btn btn-square btn-primary rounded-circle me-2" href="https://intellivisioninc.azurewebsites.net/"><i
                                class="fab fa-twitter"></i></a>
                        <a class="btn btn-square btn-primary rounded-circle me-2" href="https://intellivisioninc.azurewebsites.net/"><i
                                class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-square btn-primary rounded-circle me-2" href="https://intellivisioninc.azurewebsites.net/"><i
                                class="fab fa-youtube"></i></a>
                        <a class="btn btn-square btn-primary rounded-circle me-2" href="https://intellivisioninc.azurewebsites.net/"><i
                                class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-4">Quick Links</h5>
                   
                    <a class="btn btn-link" href="/#Home">Home</a>
                    <a class="btn btn-link" href="/#About">About Us</a>
                    <a class="btn btn-link" href="/#Services">Our Services</a>
                    <a class="btn btn-link" href="/#Career">Career</a>
                    <a class="btn btn-link" href="/#Contact">Contact Us</a>
                </div>
                <div class="col-lg-4 col-md-6">
                <h5 class="text-white mb-4">Our Services</h5>
                   
                   <a class="btn btn-link" href="/#Services">IT Services</a>
                   <a class="btn btn-link" href="/#Services">Consulting</a>
                   <a class="btn btn-link" href="/#Services">Maintnance & Support</a>
                   <a class="btn btn-link" href="/#Services">Artificial Intelligence</a>
                   <a class="btn btn-link" href="/#Services">Staffing</a>
                   <a class="btn btn-link" href="/#Services">SAP</a>
                </div>
              
            </div>
        </div>
    </div>
    <div class="container-fluid copyright bg-dark py-4">
        <div class="container text-center">
            <p class="mb-2">Copyright &copy; <a class="fw-semi-bold" href="#">IntelliVision Inc.</a>, All Right Reserved.<br></br>
            Designed by <a   class="fw-semi-bold" href="https://www.knackhook.com/" target="_blank"style={{textDecoration:'none'}}>KnackHook</a>
            </p>
           
    <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i
            class="bi bi-arrow-up"></i></a>   
        </div>
    </div>

    </div>
  )
}

export default Footer