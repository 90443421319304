import React from 'react';
import img from '../assets/images/background.jpg'
const Section = () => {
  return (
    <div id="carousel">
      <div className="container-fluid px-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100"
                src={img}
                alt="Image"
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 text-start">
                      <h1 className="display-1 text-white mb-5 animated slideInRight" alt='Welcome to IntelliVision'>
                        Welcome to IntelliVision
                      </h1>
                      <p className="fs-5 fw-medium text-primary animated slideInRight">
                        IntelliVision as a leading consulting, business solution, and systems
                        integration firm delivering solutions that benefit our clients
                      </p>
                      <a href="#About" className="btn btn-primary py-3 px-5 animated slideInRight">
                        More Info
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Section;
