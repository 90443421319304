import React from 'react'

const Topbar = () => {
  return (
    <div id="Topbar">
    <div class="container-fluid bg-dark px-0">
        <div class="row g-0 d-none d-lg-flex">
            <div class="col-lg-6 ps-5 text-start">
                <div class="h-100 d-inline-flex align-items-center text-white">
                    <span>Follow Us:</span>
                    <a class="btn btn-link text-light" href="https://intellivisioninc.azurewebsites.net/"><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-link text-light" href="https://intellivisioninc.azurewebsites.net/"><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-link text-light" href="https://intellivisioninc.azurewebsites.net/"><i class="fab fa-linkedin-in"></i></a>
                    <a class="btn btn-link text-light" href="https://intellivisioninc.azurewebsites.net/"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
            <div class="col-lg-6 text-end">
                <div class="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
                    <span class="fs-5 fw-bold me-2"><i class="fa fa-phone-alt me-2"></i>Call Us:</span>
                    <span class="fs-5 fw-bold">+1 (980) 525-4567</span>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Topbar