import React from 'react'
import image1 from '../assets/images/careernbg.png'
import {Link} from 'react-router-dom'


const Career = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Add smooth scrolling behavior for a nice effect
        });
      };
  return (
    <div id="Career">
    <div class="container-xxl py-5">
<div class="container">
    <div class="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" >
        <p class="fw-medium text-uppercase text-primary mb-2">Career</p>
        <h1 class="display-5 mb-4" alt='Explore the Benefits with Us'>Explore the Benefits with Us</h1>
    </div>
    <div className="row ">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 scale-up-center">
            <div className="cyber-about-img text-center mb-30 mb-lg-0">
                <img src={image1} alt="Career" className="img-fluid"/>
                
            </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
            <h6 className="who">About Career</h6>
            <p style={{textAlign:"justify"}}>Picture yourself at one of the world's best places to work, surrounded by teams and people who challenge you, support you, and inspire you to be extraordinary.</p>
           
            <div class="d-flex1 justify-content-center1 align-items-center1  ">
            <Link to="/IntelliCareers" target="_top">
            <div class="d-flex1 justify-content-center1 align-items-center1  ">
            <button  type="button" className="btn btn-primary">
    Apply Now
 
</button>
</div>
</Link>

</div>

         </div>
</div>
</div>
</div>
</div>
  )
}

export default Career
