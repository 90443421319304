import React from 'react'
import img1 from '../assets/images/ITservices.png'
import img2 from '../assets/images/consultant.png'
import img3 from '../assets/images/support.png'
import img4 from '../assets/images/staffing.png'
import img5 from '../assets/images/ai.png'
import img6 from '../assets/images/sap.png'
const Services = () => {
  return (
    <div id="Services">
        <div class="container-xxl py-5">
    <div class="container">
        <div class="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '600px'}}>
            <p class="fw-medium text-uppercase text-primary mb-2">Our Services</p>
            <h1 class="display-5 mb-4" alt='What We Offer for You'>What We Offer for You</h1>
        </div>
        <div class="row gy-5 gx-4">
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item">
                    <img class="img-fluid" src={img1} alt="IT Services"/>
                    <div class="service-img">
                        <img class="img-fluid"  src={img1}alt="IT Services"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">IT Services</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0"> IntelliVision.,  Consultants provide custom solutions for business continuity management. Integration of advanced, innovative IT systems that are designed to meet your business. IT is the backbone of the modern business or organization.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-item">
                    <img class="img-fluid" src={img2} alt="Consulting"/>
                    <div class="service-img">
                        <img class="img-fluid" src={img2} alt="Consulting"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">Consulting</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0">IntelliVision Consulting services facilitate customers in process and technology-driven transformation to achieve business outcomes..We provide end-to-end IT consulting services over a spectrum of technologies, platforms and frameworks. Our IT Consulting Services build the gap between business and IT and help clients gain more value from their IT-enabled investments.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-item">
                    <img class="img-fluid" src={img3} alt="Maintnance & Support"/>
                    <div class="service-img">
                        <img class="img-fluid" src={img3} alt="Maintnance & Support"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">Maintnance & Support</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0">IntelliVision Inc. has worked with some of the best banks worldwide, providing them mission-critical solutions and services.Our talented resource pool has vast expertise in various technologies and domains. Our extensive internal training programs and experienced trainers enhance both technical and team skills.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gy-5 gx-4 mt-5">
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item">
                    <img class="img-fluid" src={img4} alt="Artificial Intelligence"/>
                    <div class="service-img">
                        <img class="img-fluid" src={img4} alt="Artificial Intelligence"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">Artificial Intelligence</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0">Artificial Intelligence is the ability of a digital computer or computer-controlled robot to perform tasks commonly associated with intelligent. We help companies seek revenue growth, cost efficiencies and service through automation and analytics. We can help you in creating best end to end.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-item">
                    <img class="img-fluid" src={img5} alt="Staffing"/>
                    <div class="service-img">
                        <img class="img-fluid" src={img5} alt="Staffing"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">Staffing</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0">IntelliVision Inc offers Staffing Services to IT Multinational organizations in various domains of talent acquisition.We have a dedicated team who follow the best hiring practices and use the latest tools to search candidates for our clients in the least possible time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-item">
                    <img class="img-fluid" src={img6} alt="SAP"/>
                    <div class="service-img">
                        <img class="img-fluid" src={img6} alt="SAP"/>
                    </div>
                    <div class="service-detail">
                        <div class="service-title">
                            <hr class="w-25"/>
                            <h3 class="mb-0">SAP</h3>
                            <hr class="w-25"/>
                        </div>
                        <div class="service-text">
                            <p class="text-white mb-0">IntelliVision Inc Run simple with the best in cloud, analytical, mobile and IT Solutions. Our SAP Services include Sap consulting and implementation, Application support & Maintenance. Get Software and Technology Solutions from SAP, the leader in business applications.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default Services