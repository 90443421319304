import React from 'react'

const Contact = () => {
  return (
    <div id="Contact">
             <div class="container-xxl ">
    <div class="container">
        <p class="fw-medium text-uppercase text-primary mb-2 text-center">Contact Us</p>
                <h1 class="display-5 mb-4 text-center" alt='Ready to Join Us'>Ready to Join Us</h1>
        <div class="row g-5 justify-content-center mb-5">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="bg-light text-center h-100 p-5">
                    <div class="btn-square bg-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                        <i class="fa fa-phone-alt fa-2x text-primary"></i>
                    </div>
                    <h4 class="mb-3">Phone Number</h4>
                    <p class="mb-2" style={{fontSize: '12px'}}>+1 (980) 525-4567</p>
                    
                    {/* <a class="btn btn-primary px-4 mt-5" href="tel:+0123456789" target="blank">Call Now <i
                            class="fa fa-arrow-right ms-2"></i></a> */}
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="bg-light text-center h-100 p-5">
                    <div class="btn-square bg-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                        <i class="fa fa-envelope-open fa-2x text-primary"></i>
                    </div>
                    <h4 class="mb-3">Email Address</h4>
                    <p class="mb-2" style={{fontSize: '12px'}}>hr@intellivisioninc.com</p>
                    {/* <a class="btn btn-primary px-4 mt-5" href="mailto:hr@intellivisioninc.com" target="blank">Email Now
                     <i class="fa fa-arrow-right ms-2"></i></a> */}
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="bg-light text-center h-100 p-5">
                    <div class="btn-square bg-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                        <i class="fa fa-map-marker-alt fa-2x text-primary"></i>
                    </div>
                    <h4 class="mb-3">Our Address</h4>
                    <p class="mb-2" style={{fontSize: '12px'}}>3333 Warrenville Road, Suite 200, Lisle, Illinois 60532, United States</p>
                    {/* <a class="btn btn-primary px-4 mt-5" href="https://goo.gl/maps/FsznshxgnULBGgkN9"
                        target="blank">Direction <i class="fa fa-arrow-right ms-2"></i></a> */}
                </div>
            </div>
        </div>
       
    </div>
</div>

    </div>
  )
}

export default Contact
