import React from 'react'
import img1 from '../assets/images/logo.png'

import 'bootstrap/dist/js/bootstrap.bundle.min';

const Navbar = () => {
  return (
    <div id='navbar'>   
  
        <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-0 pe-5">
    <a href="/Home" class="navbar-brand ps-5 me-0">
   <img className="Logo " src={img1} alt="Intellivision Inc Logo"/>
    </a>

    <button type="button" class="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
        
            <a  href="/#Home" class="nav-item nav-link active">Home</a>
            <a href="/#About" class="nav-item nav-link">About</a>
            <a  href="/#Services" class="nav-item nav-link">Services</a>
            <a  href="/#Career" class="nav-item nav-link">Career</a>
            <a href="/#Contact" class="nav-item nav-link">Contact</a>
           
        </div>
    </div>
</nav>
</div>
  )
}

export default Navbar