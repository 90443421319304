import React from 'react'
import img1 from '../assets/images/about1.jpg'
import img2 from '../assets/images/about2.jpg'
const About = () => {
  return (
    <div id="About">
        <div class="container-xxl py-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-lg-6">
                <div class="row gx-3 h-100">
                    <div class="col-6 align-self-start wow fadeInUp" data-wow-delay="0.1s">
                        <img class="img-fluid" src={img1} alt='About'/>
                    </div>
                    <div class="col-6 align-self-end wow fadeInDown" data-wow-delay="0.1s">
                        <img class="img-fluid" src={img2} alt='about'/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <p class="fw-medium text-uppercase text-primary mb-2">Who We Are </p>
                <h1 class="display-5 mb-4" alt="IntelliVision Inc - Software Solutions">IntelliVision Inc - Software Solutions</h1>
                <p class="mb-4">We are an End to End IT Solutions provider with over a decade experience in Business Consulting, IT Integration, Project Management and Staff Augmentation. We take pride in our philosophy of "Organic Growth". We are a fast growing software consulting company offering software development solutions to all our clients through offshore and onsite services


                </p>
                <div class="d-flex align-items-center mb-4">
                    <div class="flex-shrink-0 bg-primary p-4">
                        <h4 class="display-2" alt="years25">Why We</h4>
                        <h5 class="text-white" style={{fontSize:'16px'}}>Free Consultations</h5>
                        <h5 class="text-white" style={{fontSize:'16px'}}>Innovative Solutions</h5>
                        <h5 class="text-white" style={{fontSize:'16px'}} >Expert Team</h5>
                    </div>
                    <div class="ms-4">
                        <p><i class="fa fa-check text-primary me-2"></i><b>Quality Product</b><br></br></p>
                        <p>We will deliver good quality products on time</p>
                        <p><i class="fa fa-check text-primary me-2"></i><b>Quick Support</b><br></br></p>
                        <p>We can respond on time and provide good support</p>
                        
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-sm-6">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                <i class="fa fa-envelope-open text-white"></i>
                            </div>
                            <div class="ms-3">
                                <p class="mb-2">Email us</p>
                                <h6 class="mb-0">hr@intellivisioninc.com</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                <i class="fa fa-phone-alt text-white"></i>
                            </div>
                            <div class="ms-3">
                                <p class="mb-2">Call us</p>
                                <h6 class="mb-0">+1 (980) 525-4567</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default About